// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-promozioni-js": () => import("./../../../src/pages/promozioni.js" /* webpackChunkName: "component---src-pages-promozioni-js" */),
  "component---src-pages-video-portfolio-js": () => import("./../../../src/pages/video-portfolio.js" /* webpackChunkName: "component---src-pages-video-portfolio-js" */),
  "component---src-templates-single-promo-js": () => import("./../../../src/templates/singlePromo.js" /* webpackChunkName: "component---src-templates-single-promo-js" */)
}

